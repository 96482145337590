import { sendEventToGTM, sendInitEventToGTM } from '@sbt-web/tracking';
import { GTM_URL, GTM_STAGE_SUFFIX } from '@shared/constants';
import { loadAsync } from '@sbt-web/utils';

async function loadGTM(): Promise<void> {
  sendInitEventToGTM();

  const url = `${GTM_URL}${
    process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT === 'stage'
      ? GTM_STAGE_SUFFIX
      : ''
  }`;
  await loadAsync({ url });

  sendEventToGTM('gtm.js', { 'gtm.start': new Date().getTime() });
}
export { loadGTM };
