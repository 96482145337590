'use client';

import { trackWebVitals } from '@sbt-web/tracking';
import { useReportWebVitals } from 'next/web-vitals';
import { getTrackingInstance } from '../../helpers/PulseTracking';

const WebVitals = (): undefined => {
  useReportWebVitals((metric) => {
    const pulseInstance = getTrackingInstance();

    if (pulseInstance !== undefined) {
      trackWebVitals(pulseInstance, metric);
    }
  });
};

export default WebVitals;
